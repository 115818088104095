import React,{Component} from "react";
import ModalWrapper from "../../components/wrapper/ModalWrapper"

export default class IdleTimeoutModal extends Component {
  

  render(){

    return(
      <ModalWrapper closeHandler={()=>this.props.closeHandler()} handleLogout={()=>this.props.handleLogout()} title="Idle Timeout" style={{height:"30vh",width:"30vw"}}>
      <div class="d-flex w-100  py-2" >

<div class="d-flex w-100" >
<div class="d-flex flex-column py-3 h-100 w-100">

 <div>You have been idle for some time. Do you want to logout or continue?</div>

   
    
</div>
</div>

  </div>
  <div class="d-flex justify-content-center align-items-center btn-group" style={{height:"90px"}}>
  <button class="btn btn-lite" onClick={()=>this.props.handleLogout()}>Logout</button>
 <button class="btn btn-info" onClick={()=>this.props.closeHandler()}>Continue</button>
  </div>
  </ModalWrapper>
    )
  }
}
