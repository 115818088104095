import update from 'immutability-helper';
export default function reducer(state = {

  screensFetching: false,
  screensFetched: false,
isUpdated:false,
  screens: [],
  addScreenStatus:null,
  addScreenTypeStatus:null,
  screenTypeData:[],
  removeScreenTypeStatus:null,
  scheduleFetching:false,
  scheduleFetched:false,
  scheduleList:null,
  saveScreenStatus:null,
  saveSplitScreenFile:false,
  screenNum:"",
  fileName:"",
  splitScreenUpload:false,
  tagsFetched: true,
  tagsFetching: false,
  tagsData:[],
  addTagStatus:null,
  locationsFetched: false,
  locationsFetching: false,
  locationData:[],
  locationStatus:null

}, action) {

  switch (action.type) {

      case "FETCH_SCREENS_ADMIN":
      return {
        ...state,
        screensFetched: false,
        screensFetching: true
      }

      case "FETCH_SCREENS_ADMIN_SUCCESS":
    var data=[];
      if(action.payload.length>0){
       data=  action.payload.map((item,i)=>{
          var tempScreenObject=Object.assign({},item);
          tempScreenObject.primeTime=tempScreenObject.primeStartTime+" - "+tempScreenObject.primeEndTime;
          return tempScreenObject;
        })
      }else {
        data=[]
      }
      return {
        ...state,
        screensFetched: true,
        screensFetching: false,
        screens:data
      }

      case "ADD_SCREEN":{
        return {
          ...state,
          addScreenStatus:0
        }
      }
      case "ADD_SCREEN_SUCCESS":{

        var newData=action.payload.data;
          newData.primeTime=newData.primeStartTime+" - "+newData.primeEndTime;

        return {
          ...state,
          addScreenStatus:1,

          screens:[...state.screens,newData]
        }
      }
      case "UPDATE_SCREEN_SUCCESS":{

        var tempArr=state.screens.map((item)=>{
          if(item.screenName==action.payload.screenIdentifier){
            var newData=update(item,{$merge:action.payload.data});
              newData.primeTime=newData.primeStartTime+" - "+newData.primeEndTime;
            return newData
          }else{
            var newData=item;
              newData.primeTime=newData.primeStartTime+" - "+newData.primeEndTime;
            return newData
          }
        })
        return {
          ...state,
          addScreenStatus:1,
          isUpdated:true,
          screens:tempArr
        }
      }
      case "ADD_SCREEN_FAILURE":{
        return {
          ...state,
          addScreenStatus:-1
        }
      }
      case "UPDATE_FINISHED":{
        return {
          ...state,
          isUpdated:false
        }
      }

      case "FETCH_SCREEN_TYPES":
        return {
          ...state,
          screenTypesFetched: false,
          screenTypesFetching: true
        }
  
        case "FETCH_SCREEN_TYPES_SUCCESS":
        return {
          ...state,
          screenTypesFetched: true,
          screenTypesFetching: false,
          screenTypeData:action.payload
        }
        case "ADD_SCREEN_TYPE":{
          return {
            ...state,
            addScreenTypeStatus:0
          }
        }
        case "ADD_SCREEN_TYPE_SUCCESS":{
          
          var newData=action.payload.data.screenTypeName;
          console.log("in reducer="+newData)
          return {
            ...state,
            addScreenTypeStatus:1,
  
            screenTypeData:[...state.screenTypeData,{value:newData,label:newData}]
          }
        }
  
        case "ADD_SCREEN_TYPE_FAILURE":{
          return {
            ...state,
            addScreenTypeStatus:-1
          }
        }

        case "REMOVE_SCREEN_TYPE_SUCCESS":{

          var newData=action.payload.screenTypeName;
  
          return {
            ...state,
            removeScreenTypeStatus:1,
  
            screenTypeData:state.screenTypeData.filter((item,i)=>(item.value!=action.payload.screenTypeName)
          )
        }

  }
        case "FETCH_SCHEDULE_DATA":
          return{
            ...state,
            scheduleFetching:true,
            scheduleFetched:false,
          }
          case "FETCH_SCHEDULE_DATA_SUCCESS":
          return{
            ...state,
            scheduleFetching:false,
            scheduleFetched:true,
            scheduleList:action.payload?action.payload:[]
          }

          case "SAVE_SCREENS_FOR_USER":
          return{
            ...state,
            screensForUserSaving:true,
            screensForUserSaved:false,
            saveScreenStatus:0
          }
          case "SAVE_SCREENS_FOR_USER_SUCCESS":

          return{
            ...state,
            screensForUserSaving:false,
            screensForUserSaved:true,
            saveScreenStatus:1
            //scheduleList:action.payload?action.payload:[]
          }
          case "SAVE_SCREENS_FOR_USER_FINISHED":{
            return {
              ...state,
              screensForUserSaved:false
            }
          }
          case "SAVE_SPLIT_SCREEN_FILES":{
            console.log("action payload="+action.payload.screenNum);
            return {
              ...state,
              screenNum:action.payload.screenNum,
              fileName:action.payload.fileName,
              saveSplitScreenFile:true
            }
          }

          case "SPLIT_SCREEN_UPLOAD":
            return {
              ...state,
              splitScreenUpload:false
            }
      
            case "SPLIT_SCREEN_UPLOAD_SUCCESS":
            return {
              ...state,
              splitScreenUpload:true
            }
            case "CLEAR_SPLIT_SCREEN_DETAILS":
              return {
                ...state,
                screenNum:"",
                fileName:"",
                splitScreenUpload:false
          }
            case "FETCH_TAGS":
              return {
                ...state,
                tagsFetched: false,
                tagsFetching: true
              }
        
              case "FETCH_TAGS_SUCCESS":
              return {
                ...state,
                tagsFetched: true,
                tagsFetching: false,
                tagsData:action.payload
              }
              case "ADD_TAG":{
                return {
                  ...state,
                  addTagStatus:0
                }
              }
              case "ADD_TAG_SUCCESS":{
        
                return {
                  ...state,
                  addTagStatus:1,
        
                  tagsData:[...state.tagsData,{value:action.payload.data.tagName,label:action.payload.data.tagName,description:action.payload.data.description}]
                }
              }
              case "UPDATE_TAG_SUCCESS":{
        
                var tempArr=state.tagsData.map((item)=>{
                  if(item.value==action.payload.tagIdentifier){
                    return Object.assign(item,{value:action.payload.data.tagName,label:action.payload.data.tagName,description:action.payload.data.description})
                  }else{
                    return item
                  }
                })
                return {
                  ...state,
                  addTagStatus:1,
                  isUpdated:true,
                  tagsData:tempArr
                }
              }
              case "ADD_TAG_FAILURE":{
                return {
                  ...state,
                  addTagStatus:-1
                }
              }
              case "UPDATE_TAG_FINISHED":{
                return {
                  ...state,
                  isUpdated:false
                }
              }  
              
              case "FETCH_LOCATIONS":
                return {
                  ...state,
                  locationsFetched: false,
                  locationsFetching: true
                }
          
                case "FETCH_LOCATIONS_SUCCESS":
                return {
                  ...state,
                  locationsFetched: true,
                  locationsFetching: false,
                  locationData:action.payload
                }
                case "ADD_LOCATION":{
                  return {
                    ...state,
                    locationStatus:0
                  }
                }
                case "ADD_LOCATION_SUCCESS":{
                  
                  var newData=action.payload.data.locationName;
                  console.log("in reducer="+newData)
                  return {
                    ...state,
                    locationStatus:1,
          
                    locationData:[...state.locationData,{value:newData,label:newData}]
                  }
                }
          
                case "ADD_LOCATION_FAILURE":{
                  return {
                    ...state,
                    locationStatus:-1
                  }
                }
        
                case "REMOVE_LOCATION_SUCCESS":{
        
                  var newData=action.payload.locationName;
          
                  return {
                    ...state,
                    locationStatus:1,
          
                    locationData:state.locationData.filter((item,i)=>(item.value!=action.payload.locationName)
                  )
                }
        
          }              

          case "UPDATE_RESET_FLAG_SUCCESS":{
        
           // var newData=action.payload.locationName;
            console.log("setting isupdated to true")
            return {
              ...state,
              //isUpdated:true
              //locationStatus:1,
    
              //locationData:state.locationData.filter((item,i)=>(item.value!=action.payload.locationName)
            //)
          }
  
    }

}
  return state;
}
