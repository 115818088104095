export const FORM_CONFIG = {theme:"boxed",
                            validTick:"d-none"
                          }

export const DATA_PROVIDER=[

   { value: 'Data', label: 'Data' },
    { value: 'WIFI', label: 'WIFI' }


]

export const ROLEMAP={
  "000":"System Admin",
  "001":"Type 1",
  "002":"Type 2",
  "003":"Type 3"

}
// export const SCREEN_TYPE=[
//    { value: 'Indoor', label: 'Indoor' },
//  { value: 'Outdoor', label: 'Outdoor' }

// ]

export const ZONE_TYPE=[
   { value: 'North', label: 'North' },
 { value: 'South', label: 'South' },
{ value: 'East', label: 'East' },
{ value: 'West', label: 'West' }


]

export const ZONE_TYPE_WADMIN=[
  { value: 'Landscape', label: 'Landscape' },
{ value: 'Portrait', label: 'Portrait' },
{ value: 'Landscape_180', label: 'Landscape_180' },
{ value: 'Portrait_270', label: 'Portrait_270' }


]
export const TIMEPICKER=[
  {value:"00",label:"00"},
  {value:"01",label:"01"},
  {value:"02",label:"02"},
  {value:"03",label:"03"},
  {value:"04",label:"04"},
  {value:"05",label:"05"},
  {value:"06",label:"06"},
  {value:"07",label:"07"},
  {value:"08",label:"08"},
  {value:"09",label:"09"},
  {value:"10",label:"10"},
  {value:"11",label:"11"},
  {value:"12",label:"12"},
  {value:"13",label:"13"},
  {value:"14",label:"14"},
  {value:"15",label:"15"},
  {value:"16",label:"16"},
  {value:"17",label:"17"},
  {value:"18",label:"18"},
  {value:"19",label:"19"},
  {value:"20",label:"20"},
  {value:"21",label:"21"},
  {value:"22",label:"22"},
  {value:"23",label:"23"}
]
export const SLOT_MAPPING=
   {
roundRobin:"Round Robin",
      slot1: '00:00 - 01:00',
    slot2: '01:00 - 02:00',
    slot3: '02:00 - 03:00',
     slot4: '03:00 - 04:00',
     slot5: '04:00 - 05:00',
      slot6: '05:00 - 06:00',
      slot7: '06:00 - 07:00',
       slot8: '07:00 - 08:00',
       slot9: '08:00 - 09:00',
        slot10: '09:00 - 10:00',
        slot11: '10:00 - 11:00',
         slot12: '11:00 - 12:00',
         slot13: '12:00 - 13:00',
          slot14: '13:00 - 14:00',
          slot15: '14:00 - 15:00',
           slot16: '15:00 - 16:00',
           slot17: '16:00 - 17:00',
            slot18: '17:00 - 18:00',
            slot19: '18:00 - 19:00',
             slot20: '19:00 - 20:00',
             slot21: '20:00 - 21:00',
              slot22: '21:00 - 22:00',
              slot23: '22:00 - 23:00',
               slot24: '23:00 - 24:00' }

      export const SOCIAL_MEDIA_TYPE=[
      { value: 'INSTAGRAM', label: 'INSTAGRAM' },
      { value: 'INSTAGRAM_STORIES', label: 'INSTAGRAM STORIES' },
      { value: 'TWITTER', label: 'TWITTER' },
      { value: 'LIVEURL', label: 'LIVE URL' },
    ]

    export const USER_ROLES_FOR_SYSADMIN=[
      { value: '001', label: 'TYPE 1' },
      { value: '002', label: 'TYPE 2' },
      { value: '003', label: 'TYPE 3' },
    ]

    export const USER_ROLES_FOR_LEVEL1=[
      { value: '002', label: 'TYPE 2' },
      { value: '003', label: 'TYPE 3' },
    ]

    export const USER_ROLES_FOR_LEVEL2=[
      { value: '003', label: 'TYPE 3' },
    ]

    export const USER_ACCESS_PERMISSIONS_LABELS={
      overview : "Overview",
      myscreens : "My Screens",
      myads : "My Creatives",
      confroomscheduler : "Conference Room Scheduler",
      scheduler : "Scheduler",
      schedulerdocs : "Scheduler-Documents",
      playlist : "Playlist",
      multipleplaylistitems : "Multiple Playlist Items",
      //reports : "Reports",
      onoffreport : "On/Off Report",
      adreport : "Ad Report",
      customerreport : "Ad Summary Report",
      covid : "Covid Report",
      screenmgt : "Screen Management",
      adgroupmgt : "Ad Group Management",
      admanagement : "Ad Management",
      screentypemgt : "Screen type Management",
      locationmgt : "Location Management",
      screensavermgt : "Screensaver Management",
      socialmedia : "Social Media",
      tagmgt : "Tag Management",
      salesgraph : "Sales Graph",
      splitscreenmgt : "Split screen Management",
      usermgt : "User Management",
      assignscreens : "Assign Screens",
      schedulemgt : "Schedule Management",
      accesspermissions : "Access Permissions",
      logindetails : "Login Details",
      conferencecalls : "Conference Calls",
      restart : "Restart"
    }

    export const BACKGROUND_COLORS=[
      //'#7bc0f7','#3b8ad9','#f18226','#ffdb69','#61737b','#a6b3b3','#e24b26','#a88add','#0cc2aa'
      '#58d6b3','#4ec7dd','#5235c4','#3c5997','#e6bc24','f18226','#E6F69D','#FF6961','#BE61CA','868686'
    ]

    export const SPLITSCREEN_TYPES={
      "layout50_50":"50-50-Ticker",
      "layout60_40":"60-40-Ticker",
      "layout80_20":"80-20-Ticker",
      "layoutFullscreen_Ticker":"Fullscreen-Ticker",
      "layoutFullscreen":"Fullscreen-No Ticker",
    }
    /*export const INTELISA_FOLDER_USERS=[
      '5dca83744dd468314c374353',
      '5ddd0b7ed58c322efc4bf0ed',
      '5def4cb2fa6ca42f596ae47e',
      '5ce6eef2623fe666061924de',
      '5d8dd49e6bf19056f02b1eed',
      '5e2e60a04c248c0a6c8eac38',
      '5e2e60f64c248c0a6c8eac3a',
      '5e2e9ed738368d55a5c8d0b4',
      '5e315fba9d3b9417b7660e41',
      '5e3938650071a75b353f2cee',
      '5e4132e76a1e9b42f2d0ed53',
      '5e42a9f636bcda3a120dc312',
      '5e4a809c5b023d5856ebc68e',
      '5e4a82055b023d5856ebc690',
      '5e58d8f421bacf63ffd2980b',
      '5e58dcc921bacf63ffd2980d',
      '5e63290cf1670d0b241be1ed',
      '5e6eedd90233e14345308614',
      '5e787d5591920c5dde0232c4',
      '5e85807eb3751946006e6ab6',
      '5ee30b83dc499a089670a261'
    ]*/

export const HOST="https://corporate.intelisa.in"
//export const HOST="http://localhost:3000"
//export const HOST="http://3.14.137.73:3000"
