import axios from "../../utils/axiosInstance";
export function fetchAdsList(){
  // return (dispatch)=>{
  //   dispatch({type:"FETCH_ADSLIST"})
  //   var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
  //   axios.post("/api/getAdsList",{loggedInUserRole}).then(function (response){
  //       dispatch({type:"FETCH_ADSLIST_SUCCESS",payload:response.data})
  //     })

  // }
  return (dispatch)=>{
    dispatch({ type: "FETCH_ADSLIST" });
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
    var arrTemp = [];
    if(loggedInUserRole=="001" || loggedInUserRole=="002"){
      axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole,owner:JSON.parse(localStorage.getItem("userObj")).userID}).then(function (response) {
        var subordinates = response.data;        
        subordinates.forEach((obj)=>{
          arrTemp.push(obj._id);
        });
        //for 002 user, should see ads and adgroups of level 1 and level 3
        (loggedInUserRole=="002")?arrTemp.push(manager_id):null;
        console.log("arrTemp="+arrTemp)
        axios.post("/api/getAdsList",{loggedInUserRole, arrTemp,owner:JSON.parse(localStorage.getItem("userObj")).userID,manager_id}).then(function (response) {

        dispatch({type:"FETCH_ADSLIST_SUCCESS",payload:response.data})
      })
      });
    } else{
    axios.post("/api/getAdsList",{loggedInUserRole,arrTemp:[manager_id],owner:JSON.parse(localStorage.getItem("userObj")).userID}).then(function (response) {

      dispatch({type:"FETCH_ADSLIST_SUCCESS",payload:response.data})
    })
  }

  }
}

export function deletemultiAd(arrdata){
  return (dispatch)=>{
    //dispatch({type:"FETCH_ADSLIST"})
    axios.post("/api/admin/deletemultiAd",{adListArr:arrdata,owner:JSON.parse(localStorage.getItem("userObj")).userID}).then(function (response){
      console.log(response)
        dispatch({type:"DELETEALL_FROM_ADSLIST_SUCCESS",payload:arrdata})
      })

  }
}

export function deleteAd(obj){
  return (dispatch)=>{
    //dispatch({type:"FETCH_ADSLIST"})
    axios.post("/api/admin/deleteAd",{...obj,owner:JSON.parse(localStorage.getItem("userObj")).userID}).then(function (response){

        dispatch({type:"DELETE_FROM_ADSLIST_SUCCESS",payload:obj.id})
      })

  }
}

export function getSignedUrl(adName,userID,splitScreenPart){
  
  console.log("*****userID in ad admin action="+userID)
  console.log("adName="+adName)
  console.log("splitScreenPart="+splitScreenPart)
  return (dispatch)=>{
    //dispatch({type:"FETCH_ADSLIST"})
    axios.post("/api/getSignedUrl",{adName,splitScreenPart,owner:JSON.parse(localStorage.getItem("userObj")).userID,
    userID:JSON.parse(localStorage.getItem("userObj")).role=="002"?JSON.parse(localStorage.getItem("userObj")).manager_id:JSON.parse(localStorage.getItem("userObj")).userID}).then(function (response){

      console.log("response config data***"+JSON.stringify(response.data.url));
      console.log("splitScreenPart="+splitScreenPart)
      if(splitScreenPart=="SLAVE"){
        dispatch({type:"SIGNEDURL_SLAVE_SUCCESS",payload:response.data})
      }else{
        dispatch({type:"SIGNEDURL_SUCCESS",payload:response.data})
      }
       
      })

  }
}
export function saveAdDetails(obj){
  return (dispatch)=>{
    //dispatch({type:"FETCH_ADSLIST"})
    axios.post("/api/admin/saveAdDetails",{obj,owner:JSON.parse(localStorage.getItem("userObj")).userID}).then(function (response){

        dispatch({type:"SAVE_AD_SUCCESS",newObj:response.data,payload:obj._id})
      })

  }
}

export function getSignedUrlForAdsList(adsArr){
  console.log("*********************getSignedUrlForAdsList")
  return (dispatch)=>{
    dispatch({type:"ADSLIST_SIGNED_URLS"})
    axios.post("/api/getSignedUrlForAdsList",{adsArr,owner:JSON.parse(localStorage.getItem("userObj")).userID
    ,userID:JSON.parse(localStorage.getItem("userObj")).role=="002"?JSON.parse(localStorage.getItem("userObj")).manager_id:JSON.parse(localStorage.getItem("userObj")).userID}).then(function (response){

        dispatch({type:"ADSLIST_SIGNED_URLS_SUCCESS",payload:response.data})
      })

  }
}

export function fetchMyAdsInfo(selectedItem){
  return (dispatch)=>{
    dispatch({type:"FETCHING_MYADS"})
    axios.post("/api/getMyAds",{adName:selectedItem.fileName}).then(function (response){  
      console.log("***response.data="+JSON.stringify(response.data))
        dispatch({type:"FETCH_MYADS_SUCCESS",payload:response.data,selectedItem})
      })

  }
}

export function addAdToDB(obj){
  return (dispatch)=>{
    //dispatch({type:"FETCH_ADSLIST"})
    axios.post("/api/addAdToDB",{obj}).then(function (response){
        console.log("****After adding ad to DB="+response.data)
        dispatch({type:"ADD_AD_SUCCESS",newObj:response.data})
      })

  }
}

export function updateStatusOfUploadedFiles(filesArr){
  return (dispatch)=>{
    dispatch({type:"UPDATESTATUS_UPLOADEDFILES"})
    axios.post("/api/updateStatusOfUploadedFiles",{filesArr}).then(function (response){  
      console.log("**response.data="+JSON.stringify(response.data));
        localStorage.setItem('filesArr',JSON.stringify(response.data));
        dispatch({type:"UPDATESTATUS_UPLOADEDFILES_SUCCESS",payload:response.data})
      })

  }
}