import React, { Component ,Suspense,lazy } from 'react';
import { Switch, Route,Redirect } from 'react-router';

import IdleTimer from 'react-idle-timer';//for session timeout
import  IdleTimeOutModal from '../pages/Auth/IdleTimeOutModal';

import Navbar from "../components/navbars/NavbarBootAll"
import PageLoader from "../components/uilib/PageLoader"

const Dashboard = lazy(() => import('./Dashboard'));
const Admin = lazy(() => import('./Admin'));
const AdminOptions = lazy(() => import('./AdminOptions'));
const Report = lazy(() => import('./Report'));
const Screen_Admin = lazy(() => import('./Admin/Screen_Admin'));
const Assign_Screens = lazy(() => import('./Admin/Assign_Screens'));
const Access_Permissions = lazy(() => import('./Admin/Access_Permissions'));
const MyScreen = lazy(() => import('./MyScreen'));
const MyAds = lazy(() => import('./MyAds'));
const ScreenDashboard = lazy(() => import('./ScreenDashboard'));
const Scheduler = lazy(() => import('./Scheduler'));
const Playlist = lazy(() => import('./Playlist'));
const OnOffReport = lazy(() => import('./OnOffReport'));
const WeeklyAdSummaryReport = lazy(() => import('./WeeklyAdSummaryReport'));
const CovidReport = lazy(() => import('./CovidReport'));
const CustomerReport = lazy(() => import('./CustomerReport'));
const AdGroup_Admin=lazy(() => import('./Admin/AdGroup_Admin'));
const Ad_Management=lazy(()=>import('./Admin/Ad_Management'));
const ScreenSaver_Management=lazy(()=>import('./Admin/ScreenSaver_Management'));
const SocialMedia_Management=lazy(()=>import('./Admin/SocialMedia_Management'));
const User_Management=lazy(()=>import('./Admin/User_Management'));
const DeviceIP_Management=lazy(()=>import('./Admin/DeviceIP_Management'));
const SoftwareVersion_Update=lazy(()=>import('./Admin/SoftwareVersion_Update'));
const Schedule_Management=lazy(()=>import('./Admin/Schedule_Management'));
const SingleScreenSelector=lazy(()=>import('./Admin/SingleScreenSelector'));
const ScreenType_Management=lazy(()=>import('./Admin/ScreenType_Management'));
const Tags_Management = lazy(() => import('./Admin/Tags_Management'));
const Location_Management = lazy(() => import('./Admin/Location_Management'));
const ConfRoomScheduler = lazy(() => import('./ConfRoomScheduler'));
const GenerateGraph = lazy(() => import('./Admin/GenerateGraph'));
const Split_Screen_Management = lazy(() => import('./Admin/Split_Screen_Management'));
const LoginDetails = lazy(() => import('./Admin/LoginDetails'));
const Select_User_For_SWVersion = lazy(() => import('./Admin/Select_User_For_SWVersion'));
import Ad_Upload_Progress from "../components/admin/Ad_Upload_Progress";

export default class Layout extends Component {
  constructor(props){
    super(props);
    this.isTimedOut= false
    this.state={
        builder:false,
        //for session timeout in milliseconds
        timeout:1000 * 60 * 10,//1000 * 1 * 60,
        showModal: false,
        userLoggedIn: false,
        progressBarVisible:"hidden",
    }
    this.closeHandler = this.closeHandler.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    //for session timeout end
  }

  _onAction(e) {
    //console.log('user did something', e)
    this.isTimedOut=false
  }
 
  _onActive(e) {
    //console.log('user is active', e)
    this.isTimedOut=false
  }
 
  _onIdle(e) {
    //console.log('user is idle', e)
    const isTimedOut = this.isTimedOut
    if (isTimedOut) {
        this.props.history.push('/logout')
    } else {
      this.setState({showModal: true})
      this.idleTimer.reset();
      this.isTimedOut=true
      // this.setState({isTimedOut: true})
    }
    
  }
  closeHandler() {
   // console.log("in handle close")
    this.setState({showModal: false})
  }

  handleLogout() {
   // console.log("in handle logout")
    this.setState({showModal: false})
    this.props.history.push('/logout')
  }
 
  openProgressBar = (visibility) =>{
    console.log("setting Ad upload progress bar to visible!!")
    this.setState({progressBarVisible:visibility});
  }

  componentDidMount(){
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
}

  render() {


    return (
      <div class="h-100">
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
        {this.state.showModal?
        <IdleTimeOutModal 
                    closeHandler={()=>this.closeHandler()}
        handleLogout={()=>this.handleLogout()}
        />:null}
          <Navbar />

          <div class="content-wrapper no-scroll">
          <Suspense fallback={<PageLoader/>}>
          <Switch>

          <Route exact path="/overview" component={Dashboard}/>
   <Route path="/admin/screenmanagement" component={Screen_Admin} exact/>
   <Route path="/admin/adgroupmanagement" component={AdGroup_Admin} exact/>
   <Route path="/admin/admanagement" component={Ad_Management} exact/>
   <Route path="/admin/screensavermanagement" component={ScreenSaver_Management} exact/>
   <Route path="/admin/screentypemanagement" component={ScreenType_Management} exact/>
   <Route path="/admin/socialmediamanagement" component={SocialMedia_Management} exact/>
   <Route path="/adminoptions/schedulemanagementscreens" component={SingleScreenSelector} exact/>
   <Route path="/adminoptions/schedulemanagement/:screenId/:source" component={Schedule_Management} exact/>
   <Route path="/adminoptions/assignscreens" component={Assign_Screens} exact/>
   <Route path="/adminoptions/accesspermissions" component={Access_Permissions} exact/>
   <Route path="/adminoptions/assignSWversiontoscreens" component={Select_User_For_SWVersion} exact/>
   
   <Route path="/admin/tagsmanagement" component={Tags_Management} exact/>
   <Route path="/admin/locationmanagement" component={Location_Management} exact/>
   <Route path="/generateGraph" component={GenerateGraph} exact/>
   <Route path="/admin/splitscreenmanagement" component={Split_Screen_Management} exact/>
   <Route path="/admin/logindetails" component={LoginDetails} exact/>
       
   <Route path="/reports/onoffreport" component={OnOffReport} exact/>
   <Route path="/reports/weeklyadsummaryreport" component={WeeklyAdSummaryReport} exact/>
   <Route path="/reports/covidreport" component={CovidReport} exact/>
   <Route path="/reports/customerreport" component={CustomerReport} exact/>
            
     {JSON.parse(localStorage.getItem("userObj")).role.toString()!="003"?
   <Route path="/adminoptions/usermanagement" component={User_Management} exact/>:null}
   {JSON.parse(localStorage.getItem("userObj")).role.toString()=="000"?
   <Route path="/admin/deviceIPmanagement" component={DeviceIP_Management} exact/>:null}
					 {JSON.parse(localStorage.getItem("userObj")).role.toString()=="000"?
   <Route path="/admin/softwareversionupdate" component={SoftwareVersion_Update} exact/>:null}

 <Route path="/admin" component={Admin} exact/>
 <Route path="/adminoptions" component={AdminOptions} exact/>
 <Route path="/scheduler" component={Scheduler} exact/>
 <Route path="/confroomscheduler" component={ConfRoomScheduler} exact/>
 <Route path="/playlist" component={Playlist} exact/>
<Route path="/reports" component={Report} exact/>
 <Route path="/myscreen/" component={MyScreen} exact/>
 <Route path="/myads/" render={props => <MyAds openProgressBar = {(visibility)=>this.openProgressBar(visibility)} />} exact/>
              <Route path="/myscreen/:id/:screen/:slave/:ticker/:layout/:syncGroup/:zone" component={ScreenDashboard} exact/>
            <Redirect from='/' to={JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("overview")!==-1 ? '/overview':
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myscreens")!==-1?'/myscreen':
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("playlist")!==-1?'/playlist':'/admin'}/>
          </Switch>
          </Suspense>
          </div>
         
{/* <div class="rounded" style={{backgroundColor:"#f5f3f3",zIndex:1500,position:"fixed",right:0,bottom:0,height:"25px",padding:5,width:"auto",maxWidth:"200px",minWidth:"100px"}}>
powered by&nbsp;<span style={{color:"#66CBC9",letterSpacing:"0.5px"}}>intel</span><span style={{color:"#B3272D",letterSpacing:"0.5px"}}>i</span><span style={{color:"#66CBC9",letterSpacing:"0.5px"}}>sa</span>
</div> */}
{this.state.progressBarVisible?
<Ad_Upload_Progress progressBarVisibility={this.state.progressBarVisible} openProgressBar = {(visibility)=>this.openProgressBar(visibility)} />
:null}
      </div>
    );
  }
}

onbeforeunload = function() { localStorage.clear(); return ''; }; 

