export default function reducer(state = {

    socialMediaFetching: false,
    socialMediaFetched: false,
    socialMediaList: [],
    addSocialMediaProfileStatus : null,
    removeSocialMediaProfileStatus : null,
    instagramAuthUrl:null,
    instagramProfiles:[],
    tokenGenerated : false
  
  }, action) {
  
    switch (action.type) {
  
        case "FETCH_SOCIALMEDIAPROFILES_ADMIN":
        return {
          ...state,
          socialMediaFetched: false,
          socialMediaFetching: true
        }

        case "FETCH_SOCIALMEDIAPROFILES_ADMIN_SUCCESS":
         return {
        ...state,
        socialMediaFetched: true,
        socialMediaFetching: false,
        socialMediaList:action.payload
      }

      case "REFRESH_SOCIALMEDIAPROFILES":
        return {
          ...state,
          socialMediaFetched: false,
          socialMediaFetching: true
        }

             
      case "ADD_SOCIAL_MEDIA_PROFILE":{
        return {
          ...state,
          addSocialMediaProfileStatus:0
        }
      }
      case "ADD_SOCIAL_MEDIA_PROFILE_SUCCESS":{

        var newData=action.payload.data;
        console.log("newData="+newData)

        return {
          ...state,
          addSocialMediaProfileStatus:1,

          socialMediaList:[...state.socialMediaList,newData]
        }
      }

      case "ADD_SOCIAL_MEDIA_PROFILE_FAILURE":{
        return {
          ...state,
          addSocialMediaProfileStatus:-1
        }
      }

      case "REMOVE_SOCIAL_MEDIA_PROFILE":{
        return {
          ...state,
          removeSocialMediaProfileStatus:0
        }
      }
      case "REMOVE_SOCIAL_MEDIA_PROFILE_SUCCESS":{

        var newData=action.payload.data;

        return {
          ...state,
          removeSocialMediaProfileStatus:1,

          socialMediaList:state.socialMediaList.filter((item,i)=>(item.socialMediaType!=action.payload.socialMediaType || 
          item.profileName!=action.payload.profileName))
        }
      }
      case "INSTAGRAM_AUTH_URL_SUCCESS":
        return {
          ...state,
          instagramAuthUrl : action.payload
        }

      case "INSTAGRAM_PROFILES_SUCCESS":
        return {
          ...state,
          instagramProfiles : action.payload
        }
   
      case "INSTAGRAM_GENERATETOKEN_SUCCESS":
        // var tempArr=state.instagramProfiles.map((profile)=>{
        //   if(profile._id==action.payload._id){
        //     var newData=update(profile,{$merge:action.payload});

        //     return newData
        //   }else{
        //     var newData=profile;

        //     return newData
        //   }
        // })
        return {
          ...state,
          //instagramProfiles : tempArr,
          tokenGenerated : true
        }
   
    }
    
    return state;
}
