import React from "React";
import {NavLink} from 'react-router-dom';
import {ROLEMAP} from "../../../constants/CONSTANTS"
import "../../../css/navbar/bootstrapNavbarAll.css";
export default class NavbarBootAll extends React.Component{

constructor(props){
  super(props);
  this.activateSearch=this.activateSearch.bind(this)
  this.state={
    searchText:'inactive'
  }
}

activateSearch(e){
  if(e.target.value!=''){
      this.setState({searchText:'active'})
  }else{
      this.setState({searchText:'inactive'})

  }

}
launchBuilder = () =>{
  this.props.handler(true)
}
  render(){
    return (
      <nav class="navbar no-icon-nav navbar-expand-lg navbar-dark bg-dark bd-navbar">
    <a class="navbar-brand h-100" href="#" style={{borderRadius:"5px"}}>
    <img src="../../images/logo.png" height="100%" width="120px" />
    </a>
      <NavLink to="/logout" class="navbar-toggler-logout d-md-none" style={{position:"absolute",right:"10px"}}><i class="fa fa-circle-o"></i> Sign Out</NavLink>


    <div class="navbar-nav-scroll navbar-collapse pl-5" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 bd-navbar-nav mt-lg-0 navbar-navigation-menu">
      {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("overview")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/overview"><i class="fas fa-columns"></i> <span>Overview</span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myscreens")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/myscreen"><i class="fas fa-desktop"></i> <span>My Screens</span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("myads")!==-1?
        <li class="nav-item" id="myads">
          <NavLink class="nav-link" activeClassName="active" to="/myads"> <span>My Creatives</span></NavLink>
        </li>
        :null}
         {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("confroomscheduler")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/confroomscheduler"><i class="fa fa-chart-bar"></i> <span>Conference Room Scheduler</span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("scheduler")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/scheduler"><i class="fa fa-chart-bar"></i> <span>Scheduler</span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("playlist")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/playlist"><i class="fas fa-columns"></i> <span>Playlist</span></NavLink>
        </li>
        :null}
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("onoffreport")!==-1 || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("adreport")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/reports"><i class="fa fa-chart-bar"></i> <span>Reports</span></NavLink>
        </li>
        :null}

        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/admin"><i class="fas fa-file-alt"></i> <span >Control Panel</span></NavLink>

        </li>
        {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("usermgt")!==-1 || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("assignscreens")!==-1 
        || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("schedulemgt")!==-1 || JSON.parse(localStorage.getItem("userObj")).permissions.indexOf("accesspermissions")!==-1?
        <li class="nav-item">
          <NavLink class="nav-link" activeClassName="active" to="/adminoptions"><i class="fas fa-file-alt"></i> <span>Admin</span></NavLink>
        </li>
        :        
        null}

      

      </ul>
      <ul class="navbar-nav ml-auto action-items d-none d-md-flex">
            <li class="nav-item  d-none  align-items-center justify-content-center" >
            <div class="navbar-search">
            <button type="submit" class="btn btn-search">
            <i class="fa fa-search">

          </i>
            </button>
        <input type="text" name="search" placeholder="search" />


      </div>
            </li>

            <li class="nav-item">
            <a class="nav-link user-avatar d-inline-flex align-items-center" >
            <span class="avatar w-32"><i class="fas fa-user-circle" style={{fontSize:"30px"}}></i></span>
            <span class="nav-text d-flex flex-column pointer pl-2 text-left" style={{lineHeight:"1"}}><span class="text-uppercase">{JSON.parse(localStorage.getItem("userObj")).userName}&nbsp;{/*JSON.parse(localStorage.getItem("userObj")).lastName*/}</span>
            <small class="text-uppercase">{ROLEMAP[JSON.parse(localStorage.getItem("userObj")).role]}</small></span>

            </a>
            <div class="treeview-menu-block">
        <ul class="treeview-menu">


        <li><NavLink to="/logout" activeClassName="active"><i class="fa fa-circle-o"></i> Sign Out</NavLink></li>
      </ul>
      </div>
            </li>

            </ul>


    </div>
  </nav>
)
  }
}
