export default function reducer(state={
  isAuthenticated:false,
  isAuthenticating:false,
  resetPWD:false,
  isResetted:false,
  isResetting:false,
  initialState:true,
  sendingResetEmail:false,
  sentResetEmail:false,
  resetEmailError:false,
  ipIncorrect:false,
  otpValid:null
  },action){
    switch (action.type) {
      case "AUTH_INIT":
      return {
        ...state,
        isAuthenticating:true,
        resetPWD:false,
        initialState:true,
        otpValid:null,
      }
      case "AUTH_SUCCESS":
  
  return {
    ...state,
    isAuthenticated:true,
    isAuthenticating:false,
    resetPWD:action.payload,
    initialState:true,
    sentResetEmail:false,
    resetEmailError:false,
    ipIncorrect:false,
  }
  case "AUTH_FAILED":
  
  return {
  ...state,
  isAuthenticated:false,
  isAuthenticating:false,
  resetPWD:false,
  initialState:false,
  sentResetEmail:false,
  resetEmailError:false,
  ipIncorrect:false,
  }
  case "UN_AUTH":
  
  return {
  ...state,
  isAuthenticated:false,
  isAuthenticating:false,
  resetPWD:false,
  initialState:true,
  ipIncorrect:false,
  }
  
  case "RESET_PWD":
  return {
    ...state,
    isResetting:true,
    isResetted:false,
    initialState:true,
    
  }
  case "RESET_PWD_SUCCESS":
  
  return {
  ...state,
  isResetted:true,
  isResetting:false,
  resetPWD:false,
  initialState:true,
  
  }
  case "RESET_PWD_FAILED":
  
  return {
  ...state,
  isAuthenticated:false,
  isAuthenticating:false,
  resetPWD:false,
  isResetted:true,
  isResetting:false,
  initialState:true
  }
  
  case "SENDMAIL_RESET":
      return {
        ...state,
        sendingResetEmail:true,
        sentResetEmail:false,
        resetEmailError:false,
        initialState:true
      }
  case "SENDMAIL_RESET_SUCCESS":
    console.log("sent reset email to true")
    return {
      ...state,
      sendingResetEmail:false,
      sentResetEmail:true,
      resetEmailError:false,
      initialState:true
    }    
  case "SENDMAIL_RESET_FAILED":
    console.log("setting email error to true")
    return {
      ...state,
      sendingResetEmail:false,
      sentResetEmail:false,
      resetEmailError:true,
  initialState:true
  }
      case "IP_INCORRECT":

      return {
      ...state,
      isAuthenticated:false,
      isAuthenticating:false,
      resetPWD:false,
      initialState:false,
      sentResetEmail:false,
      resetEmailError:false,
      ipIncorrect:true,
      }
      case "OTP_VALIDATED":
        console.log("***Is otp valid?="+JSON.stringify(action.payload))
        return{
          ...state,
          otpValid:action.payload.otpValid,
          
        }
      case "LOGIN_AGAIN":
        console.log("In Login again!")
        return{
          ...state,
          otpValid:null,    
        }
    }
    return state;
  }
  