import update from 'immutability-helper';
export default function reducer(state = {

  usersFetching: false,
  usersFetched: false,
isUpdated:false,
  users: [],
  usersForRoleFetched: false,
  usersForRoleFetching: false,
  usersForRole:[],
  addUserStatus:null,
  deviceIPsFetched: false,
  deviceIPsFetching: false,
  softwareVersionsFetched: false,
  softwareVersionsFetching: false,
  devices: [],
  softwareversions:[],
  updateDeviceIPStatus:null,
  updateSoftwareVersionStatus:null,
  screensLevel1Fetched: true,
  screensLevel1Fetching: false,
  screensLevel1:null,
  s3LogFilesFetched: false,
  s3LogFilesFetching: false,
  s3LogFiles:[],
  savingPlaylist:false,
  savedPlaylist:false,
  selectedPlaylist:null,
  addPlaylistNameStatus:0,
  playlists:[],
  playlistsFetching:false,
  playlistsFetched:false,
  generatingGraph:false,
  graphGenerated:false,
  salesUploadDataFetching:false,
  salesUploadDataFetched:false,
  salesUploadData:[],
  gettingExcludeAdsFile:false,
  gotExcludeAdsFile:false,
  gotManagerForUser:false,
  gotUsersForAccess:false,
  deletedPlaylist:false,
  selectedTwitterPlaylist:null,
  selectedInstagramPlaylist:null
}, action) {

  switch (action.type) {

      case "FETCH_USERS_ADMIN":
      return {
        ...state,
        usersFetched: false,
        usersFetching: true
      }

      case "FETCH_USERS_ADMIN_SUCCESS":
    var data=[];
      if(action.payload.length>0){
        
       data= action.payload
      }else {
        data=[]
      }
      return {
        ...state,
        usersFetched: true,
        usersFetching: false,
        users:data
      }

      case "FETCH_USERS_FOR_ROLE":
      return {
        ...state,
        usersForRoleFetched: false,
        usersForRoleFetching: true
      }

      case "FETCH_USERS_FOR_ROLE_SUCCESS":
       return {
        ...state,
        usersForRoleFetched: true,
        usersForRoleFetching: false,
        usersForRole:action.payload,
        addUserStatus:0
      }


      case "FETCH_DEVICEIPS_ADMIN":
      return {
        ...state,
        deviceIPsFetched: false,
        deviceIPsFetching: true
      }

      case "FETCH_DEVICEIPS_ADMIN_SUCCESS":
    var data=[];
      if(action.payload.length>0){
       data= action.payload
      }else {
        data=[]
      }
      return {
        ...state,
        deviceIPsFetched: true,
        deviceIPsFetching: false,
        devices:data
      }

      case "FETCH_SOFTWARE_VERSIONS":
        return {
          ...state,
          softwareVersionsFetched: false,
          softwareVersionsFetching: true
        }
  
        case "FETCH_SOFTWARE_VERSIONS_SUCCESS":
      var data=[];
        if(action.payload.length>0){
         data= action.payload
        }else {
          data=[]
        }
        return {
          ...state,
          softwareVersionsFetched: true,
          softwareVersionsFetching: false,
          softwareversions:data
        }      

      case "ADD_USER":{
        return {
          ...state,
          addUserStatus:0
        }
      }
      case "ADD_USER_SUCCESS":{

        var newData=action.payload.data;


        return {
          ...state,
          addUserStatus:1,
          users:[...state.users,newData]
        }
      }
      case "UPDATE_USER_SUCCESS":{

        var tempArr=state.users.map((item)=>{
          if(item.userName==action.payload.userIdentifier){
            var newData=update(item,{$merge:action.payload.data});

            return newData
          }else{
            var newData=item;

            return newData
          }
        })
        return {
          ...state,
          addUserStatus:1,
          isUpdated:true,
          users:tempArr
        }
      }
      case "ADD_USER_FAILURE":{
        return {
          ...state,
          addUserStatus:-1
        }
      }
      case "UPDATE_FINISHED":{
        return {
          ...state,
          isUpdated:false
        }
      }
      case "UPDATE_DEVICEIPS_ADMIN":{
        return {
          ...state,
          updateDeviceIPStatus:0
        }
      }

      case "UPDATE_DEVICEIPS_ADMIN_SUCCESS":{

        var tempArr=state.devices.map((item)=>{
          if(item.deviceIMEI==action.payload.deviceIMEI){
            var newData=update(item,{$merge:action.payload});

            return newData
          }else{
            var newData=item;

            return newData
          }
        })
        return {
          ...state,
          updateDeviceIPStatus:1,
          isUpdated:true,
          devices:tempArr
        }
      }

      case "UPDATE_SOFTWARE_VERSION":{
        return {
          ...state,
          updateSoftwareVersionStatus:0
        }
      }

      case "UPDATE_SOFTWARE_VERSION_SUCCESS":{

        var tempArr=state.softwareversions.map((item)=>{
          if(item._id==action.payload._id){
            var newData=update(item,{$merge:action.payload});

            return newData
          }else{
            var newData=item;

            return newData
          }
        })
        return {
          ...state,
          updateSoftwareVersionStatus:1,
          isUpdated:true,
          softwareversions:tempArr
        }
      }


      case "FETCH_S3LOGFILENAMES":
        return {
          ...state,
          s3LogFilesFetched: false,
          s3LogFilesFetching: true
        }
  
        case "FETCH_S3LOGFILENAMES_SUCCESS":
      var data=[];
        if(action.payload.length>0){
          
         data= action.payload
        }else {
          data=[]
        }
        return {
          ...state,
          s3LogFilesFetched: true,
          s3LogFilesFetching: false,
          s3LogFiles:data
        }

        case "CLEAR_TWITTERINTA_PLAYLIST":
          return {
            ...state,
            selectedTwitterPlaylist:null,
            selectedInstagramPlaylist:null
          }

        case "SAVE_PLAYLIST_NAME":
          return {
            ...state,
            savingPlaylist:true,
            addPlaylistNameStatus:0
          }
          case "SAVE_PLAYLIST_NAME_SUCCESS":
            var newData = action.payload;
            return {
              ...state,
              savingPlaylist:false,
              addPlaylistNameStatus:1,
              playlists:[...state.playlists,newData]
            }
          case "DELETE_PLAYLIST":
            return {
              ...state,
              deletedPlaylist:false
            }
          case "DELETE_PLAYLIST_SUCCESS":
            var newData = action.payload;
            return {
              ...state,
              deletedPlaylist:true,
              playlists:state.playlists.filter((item,i)=>(item.playlistName!=action.payload.playlistName))
            }
            case "GET_PLAYLISTS":
              return {
                ...state,
                playlistsFetching:true,
                playlistsFetched:false
              }
              case "GET_PLAYLISTS_SUCCESS":
                  var data=[];
                  if(action.payload.length>0){
                    
                   data= action.payload
                  }else {
                    data=[]
                  }
                return {
                  ...state,
                  playlistsFetching:false,
                  playlistsFetched:true,
                  playlists:data
                }

        case "UPDATE_PLAYLIST":
          return {
            ...state,
            savingPlaylist:true,
            savedPlaylist:false
          }
          case "UPDATE_PLAYLIST_SUCCESS":
            return {
              ...state,
              savingPlaylist:false,
              savedPlaylist:true,
              selectedPlaylist:action.payload
            }
            case "UPDATE_TWITTER_PLAYLIST_SUCCESS":
              return {
                ...state,
                savingPlaylist:false,
                savedPlaylist:true,
                selectedTwitterPlaylist:action.payload
              }
              case "UPDATE_INSTAGRAM_PLAYLIST_SUCCESS":
                return {
                  ...state,
                  savingPlaylist:false,
                  savedPlaylist:true,
                  selectedInstagramPlaylist:action.payload
                }
            

          case "GENERATE_GRAPH":
            return {
              ...state,
              generatingGraph:true,
              graphGenerated:false
            }
            case "GENERATE_GRAPH_SUCCESS":
              var newData = action.payload;
              return {
                ...state,
                generatingGraph:false,
                graphGenerated:true,
                isUpdated:true,
                //salesUploadData:
                salesUploadData:[newData,...state.salesUploadData]
              }
              
          case "GET_SALES_UPLOAD_DATA":
            return {
              ...state,
              salesUploadDataFetching:true,
              salesUploadDataFetched:false
            }
            case "GET_SALES_UPLOAD_DATA_SUCCESS":
                var data=[];
                if(action.payload.length>0){
                  console.log("action.payload="+action.payload[0].excelName)
                  data= action.payload
                }else {
                  data=[]
                }
              return {
                ...state,
                salesUploadDataFetching:false,
                salesUploadDataFetched:true,
                salesUploadData:data,
                message:false
              } 
              
              case "GET_EXCLUDE_ADS_FILE":
                return {
                  ...state,
                  gettingExcludeAdsFile:true,
                  gotExcludeAdsFile:false
                }
                case "GET_EXCLUDE_ADS_FILE_SUCCESS":
                  return {
                    ...state,
                    gettingExcludeAdsFile:false,
                    gotExcludeAdsFile:true
            }
case "GET_MANAGER_FOR_USER_SUCCESS":
                  return {
                    ...state,
                    gotManagerForUser:true
                  }
                case "GET_USERS_FOR_ACCESS":
                  return {
                    ...state,
                    gotUsersForAccess:true,
                    users:action.payload
                  } 
                  case "UPDATE_PERMISSIONS":
                    return {
                      ...state
                  } 
  }
  return state;
}
