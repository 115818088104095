import axios from "axios"

import {HOST} from "../../constants/CONSTANTS"
var axiosInstance = axios.create({
  baseURL: HOST,
  timeout: 2000000,


});

/*axiosInstance.interceptors.request.use(req => {
  console.log("** in req interceptor"
  +JSON.stringify(req))
  console.log("url="+req.url)
  axios.defaults.headers.common['Auth-Token'] = localStorage.getItem("token");
  console.log(axios.defaults.headers.common['Auth-Token'])
  if (req.url=="/api/login" || axios.defaults.headers.common['Auth-Token']) 
  {
    console.log("in******")
    return req;
  }
  throw ({message:"the token is not available"});
 },error=>{
  return Promise.reject(error);
 }
);*/

axios.defaults.headers.common['Auth-Token'] = localStorage.getItem("token");
module.exports = axiosInstance;
