import jwt_decode from "jwt-decode"
import { useMediaQuery } from 'react-responsive'
var bcrypt = require('bcryptjs');

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}


export function isTokenValid(token){

let decoded = jwt_decode(token);
console.log("decoded="+decoded)
if (Date.now() / 1000 < decoded.exp) {
  return true;
}
return false
}

export function getTokenData(token){
  return jwt_decode(token);
}

export function gradientGen(graphID,color1,color2){
  var gradient =  document.getElementById(graphID).getContext('2d').createLinearGradient(0, 0, 0, 500);
gradient.addColorStop(0, color1);
gradient.addColorStop(1, color2);
return gradient;

}

export function setPassword(password){
  var salt = bcrypt.genSaltSync(10);
  // this.password = password

  var hash = bcrypt.hashSync(password, salt);
  return hash;
}

export function validPassword(password,hash){
  return bcrypt.compareSync(password, hash);
}
