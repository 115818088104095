import update from 'immutability-helper';
export default function reducer(state={
  adsListFetched:false,
  adsListFetching:false,
  adsList:[],
  scheduleBucketReady:false,
  scheduleBucket:null,
  selectedItem:null,
  selectedAdList:null,
  selectedAdFetched : false,
  selectedAdFetching : false,
  myAdsInfo : [],
  myAdsInfoFetching:false,
  myAdsFetched:false,
  selectedItem:null,
},action){

  switch (action.type) {
    case "FETCH_ADSLIST":

  return {
  ...state,
  adsListFetched:false,
  adsListFetching:true
  }

  case "FETCH_ADSLIST_SUCCESS":
let bucket={};
action.payload.forEach((item,i)=>{

  return   Object.assign(bucket,{[item["_id"]]:["A","B","C","D","E","F"]})
})
return {
...state,
adsListFetched:true,
adsListFetching:false,
adsList:action.payload,
scheduleBucket:bucket
}

case "DELETE_FROM_ADSLIST_SUCCESS":{
  return {
    ...state,
    adsList:state.adsList.filter((item,i)=>item["_id"]!=action.payload)
  }
}

case "DELETEALL_FROM_ADSLIST_SUCCESS":{
  let data =action.payload
  var copiedArr = [...state.adsList];
  // let copiedArr =state.adsList
    for(let i = 0; i < data.length; i++){
      let index = copiedArr.findIndex(x => x._id ===data[i]._id);
        copiedArr.splice(index,1)
    }
  return {
    ...state,
    adsList:copiedArr
  }
}
case "REMOVE_SLOT_FROM_BUCKET":

let tempObj=Object.assign(state.scheduleBucket,{[action.payload.item]:state.scheduleBucket[action.payload.item].filter((data)=>data!=action.payload.slot)})
return {
  ...state,
  scheduleBucket:tempObj
}
case "ADD_SLOT_TO_BUCKET":
var tempObj=Object.assign({},state.scheduleBucket);
var tempArr=tempObj[action.payload.itemKey];
tempArr.push(action.payload.slot);

let tempSlotObj=update(state,{scheduleBucket:{[action.payload.itemKey]:{$set:tempArr.sort()}}}).scheduleBucket;
// let tempObj=Object.assign(state.scheduleBucket,{[action.payload.item]:state.scheduleBucket[action.payload.item].filter((data)=>data!=action.payload.slot)})
return {
  ...state,
  scheduleBucket:tempSlotObj
}
case "SCHEDULE_BUCKET_READY":
return {
  ...state,
  scheduleBucketReady:true
}
case "SAVE_AD_SUCCESS":{
    console.log("ion.newObj="+JSON.stringify(action.newObj))
    return {
      ...state,
      adsList:state.adsList.map((item,i)=>{
        if(item["_id"]==action.payload)
        {
          return action.newObj;
        }else{
          return item;
        }
      })
    }
  }
  case "FETCHING_MYADS":{
    return{
      ...state,
      myAdsInfo:[],
      myAdsInfoFetching:true
    }
  }
case "FETCH_MYADS_SUCCESS":{
  console.log("action.selectedItem="+JSON.stringify(action.selectedItem))
  return{
    ...state,
    myAdsInfo:action.payload,
    myAdsInfoFetching:false,
    myAdsFetched:true,
    selectedItem:action.selectedItem
  }
}

case "ADSLIST_SIGNED_URLS" :{
  return{
    ...state,
    selectedAdFetched : false,
    selectedAdFetching : true
  }
}

case "ADSLIST_SIGNED_URLS_SUCCESS" :{
  return{
    ...state,
    selectedAdList:action.payload,
    selectedAdFetched : true,
    selectedAdFetching : false
  }
}

case "FETCHING_MYADS":{
  return{
    ...state,
    myAdsInfo:[],
    myAdsInfoFetching:true
  }
}
case "FETCH_MYADS_SUCCESS":{
console.log("action.selectedItem="+JSON.stringify(action.selectedItem))
return{
  ...state,
  myAdsInfo:action.payload,
  myAdsInfoFetching:false,
  myAdsFetched:true,
  selectedItem:action.selectedItem
}
}

case "ADD_AD_SUCCESS":{
  //console.log("**action.newObj="+JSON.stringify(action.newObj));
  var newAdsArr = [...state.adsList,action.newObj];
  //console.log("***newAdsArr="+JSON.stringify(newAdsArr))
  return {
    ...state,
    adsList:newAdsArr
    }
  }

  case "UPDATESTATUS_UPLOADEDFILES":{
    return{
      ...state,    
    }
    }
  
  case "UPDATESTATUS_UPLOADEDFILES_SUCCESS":{
    return{
      ...state,   
      //filesArr:action.payload 
    }
    }

}

  return state;
}
