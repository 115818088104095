import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';

import store from './store';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import "../css/bootstrap.css";
import "../css/customStyle.css";
import "../css/all.css";
import {isTokenValid} from "./utils/utility.js"
import AppContainer from "./pages/AppContainer";

const token = localStorage.getItem('token')

if (token) {
  if(isTokenValid(token)){
    let data = sessionStorage.getItem('token');
    console.log("data="+data)
    if((JSON.parse(localStorage.getItem("userObj")).resetPWD==false) && (data == "true")){
      store.dispatch({type: "AUTH_SUCCESS",payload:false})

    }else{
        store.dispatch({type: "UN_AUTH"})
    }
  }else{
    console.log("token expired!")
    store.dispatch({type: "UN_AUTH"})
  }

}



ReactDOM.render(
<Provider store={store}>
  <AppContainer />
</Provider>
  , document.getElementById("app"));
