import axios from "../../utils/axiosInstance";

//import axios from "axios"
import {HOST} from "../../../constants/CONSTANTS"
import {getTokenData,validPassword,setPassword} from "../../utils/utility"
export function registerUser(userObj){
  return (dispatch)=>{

    axios.post(`/api/register`,userObj).then(function (response){
      })

   }
}
export function login(userObj){
  return (dispatch)=>{
    dispatch({type:"AUTH_INIT"})
    const userobj1 = Object.assign({status:0}, userObj);
    delete userobj1.pass
    axios.post(`/api/login`,userobj1).then(function (response){
      // console.log(response)
       var encryptPass =  validPassword(userObj.pass, response.data.hash);
      console.log(encryptPass)
      if(encryptPass == true){
        userobj1.status =1
        axios.post(`/api/login`,userobj1).then(function (res){
          localStorage.setItem("token",res.data.token)
      localStorage.setItem("userObj",JSON.stringify(res.data.userObject))
      sessionStorage.setItem('token', "true");
      axios.defaults.headers.common['Auth-Token'] = res.data.token;
        dispatch({type:"AUTH_SUCCESS",payload:res.data.userObject.resetPWD})
        }).catch((err)=>{
          console.log("In error of Login"+err.response.status);
          if(err.response.status=='401'){
            dispatch({type:"IP_INCORRECT"})
          } else {
            dispatch({type:"AUTH_FAILED"})
          }
          
        })
      }else{
        dispatch({type:"AUTH_FAILED"})
      }
      }).catch((err)=>{
        console.log("err="+err)
        dispatch({type:"AUTH_FAILED"})
      })

   }
}
export function sendEmailForReset(userName){
  return (dispatch)=>{
    dispatch({type:"SENDMAIL_RESET"})
    axios.post(`/api/sendEmailForReset`,{userName}).then(function (response){
        dispatch({type:"SENDMAIL_RESET_SUCCESS"})
      }).catch((err)=>{
        console.log("err="+err)
        dispatch({type:"SENDMAIL_RESET_FAILED"})
      })

   }
}


export function resetPassword(userObj){
  return (dispatch)=>{
    dispatch({type:"RESET_PWD"})
    const userObj1 = Object.assign({status:0}, userObj);
    userObj1.password = setPassword(userObj.password)
    userObj1.confirmPassword = setPassword(userObj.confirmPassword)
    delete userObj1['newPassword']
    axios.post(`/api/changePassword`,userObj1).then(function (response){

      var encryptPass =  validPassword(userObj.password, response.data.hash);
      console.log(encryptPass)
      if(encryptPass == true){
        userObj1.status =1
        axios.post(`/api/changePassword`,userObj1).then(function (res){
          localStorage.setItem("token",res.data.token)
      localStorage.setItem("userObj",JSON.stringify(res.data.userObject))
        axios.defaults.headers.common['Auth-Token'] = res.data.token;
        dispatch({type:"RESET_PWD_SUCCESS",payload:res.data.userObject.resetPWD})
        })
      }else{
        dispatch({type:"RESET_PWD_FAILED"})
      }

      }).catch((err)=>{
        dispatch({type:"RESET_PWD_FAILED"})
      })

   }
}

export function logout(){
  return (dispatch)=>{

    localStorage.removeItem("token")
    localStorage.removeItem("userObj");
    localStorage.removeItem("aduploadProgressVisibility");
    localStorage.removeItem("filesArr");
      dispatch({type:"UN_AUTH"})

   }
}

export function validateOTP(userID,OTP){
  return (dispatch)=>{
  axios.post('/api/validateOTP',{owner:userID,OTP}).then(function (response){
    dispatch({type:"OTP_VALIDATED",payload:response.data})
  })
  }
}

export function loginAgain(){
  return (dispatch)=>{
    localStorage.removeItem("token")
    localStorage.removeItem("userObj");
    localStorage.removeItem("locale");
    dispatch({type:"UN_AUTH"})
    dispatch({type:"LOGIN_AGAIN"});
  }
}