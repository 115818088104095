import React from "react";
import {login,sendEmailForReset,validateOTP,loginAgain} from "../../redux/actions/authAction.js"
import ActionButton from "../formcomponent/ActionButton"
import {connect} from "react-redux";
import Input from "../formcomponent/Input"
import ComponentLoader from "../../components/uilib/ComponentLoader";
import DialogBox from "../../components/DialogBox"
import { Redirect } from 'react-router-dom'
import {setPassword} from "../../utils/utility.js"
import Countdown from 'react-countdown';

@connect((store)=>{
  return {
isAuthenticating:store.auth.isAuthenticating,
isAuthenticated:store.auth.isAuthenticated,
initialState:store.auth.initialState,
sendingResetEmail:store.auth.sendingResetEmail,
sentResetEmail:store.auth.sentResetEmail,
resetEmailError:store.auth.resetEmailError,
ipIncorrect:store.auth.ipIncorrect,
otpValid:store.auth.otpValid

  }
})
export default class Login_Form extends React.Component {
  constructor(props){
super(props);
this.state={

  userName :'',

  password:"",
  pass:"",
  resetRequest:false,
  otp:"",
  otpValid: false,
  needToCheckOTP:false,
  otpExpired:false, 
}
  }


  login = () => {
    if(this.state.userName!=="" && this.state.password!==""){
      this.state.pass= this.state.password
      var hash = setPassword(this.state.password)
      // console.log(hash)
      
      this.state.password =hash
      // console.log(this.state)
      this.props.dispatch(login(this.state))
    }
    
  };
  resetPassword = () =>{
    if(this.state.userName!==""){
      this.setState({resetRequest:true});
    }
  }
  confirmResetPassword = () =>{
      if(this.state.userName!==""){
      this.props.dispatch(sendEmailForReset(this.state.userName));
      this.setState({resetRequest:false});
    }
    
  }

  setFieldValue=({target})=>{
     this.setState({[target.name]:target.value})
   }
componentWillReceiveProps(nextProps){
  console.log("sendingResetEmail="+nextProps.sendingResetEmail)
  console.log("sentResetEmail="+nextProps.sentResetEmail)
  //console.log("userID="+JSON.parse(localStorage.getItem("userObj")).userID)
console.log("reset request="+this.state.resetRequest);
if(localStorage.getItem("userObj")!==null 
&& JSON.parse(localStorage.getItem("userObj")).userID=="6495224112ef38106c36b927"
//&& JSON.parse(localStorage.getItem("userObj")).resetPwd==false
){
  this.setState({needToCheckOTP:true});
  console.log("***nextProps.otpValid="+nextProps.otpValid)
  if(nextProps.otpValid!==null && nextProps.otpValid=="valid"){
    console.log("OTP is valid!");
    this.setState({otpValid:true})
  } else {
    console.log("OTP is invalid!");
    this.setState({otpValid:false})
  }
}
}

resetDialog = () =>{
  this.setState({resetRequest:false});
}

validateUserName = (value) =>{
  if(!value.toString().match(/^[a-zA-Z0-9_]+$/g)){
    console.log("not allowed")
    return false;
  }
  return true;
}

validatePassword = (value) =>{
  if(!value.toString().match(/^[a-zA-Z0-9!(\)-.?[\]_`~;:!@#$%^&*+=-]+$/g)){
    console.log("not allowed")
    return false;
    
  }
  return true;
}
checkOTP = () =>{
  if(this.state.otp!==""){
    //this.setState({otpValid : true})
    this.props.dispatch(validateOTP(JSON.parse(localStorage.getItem("userObj")).userID,this.state.otp));
  }
}
loginAgain = () =>{
  this.setState({needToCheckOTP:false,otpExpired:false,});
  this.props.dispatch(loginAgain());  
  
}

countdownCompleted = () =>{
  console.log("countdown completed!");
  this.setState({otpExpired:true});
}

resendOTP = () =>{
  this.setState({otpExpired:false,otpValid:false});
  this.props.dispatch(login(this.state)); 
}

getRedirectPath() {
  console.log("***In getredirectpath=")
  // const locationState = this.props.location.state
  // if (locationState && locationState.from.pathname) {
  //   return locationState.from.pathname // redirects to referring url
  // } else {
    return '/'
  //}
}

  render() {
    //causing Max update depth exceeded error
    // if(!this.state.needToCheckOTP || (this.state.otpValid && this.state.needToCheckOTP)){
      //redirect to portal for tata_coffee when otp is valid
      if(this.state.otpValid){
      return(
      <Redirect to={{
       pathname: this.getRedirectPath(), state: {
         from: this.props.location
       }
     }}/>)
    }

    return (


      <div class="login-form d-flex no-shadow border-0  card" style={{height:"400px",width:"350px",background:"transparent"}}>

        <div class="d-flex align-items-center justify-content-center" style={{height:"80px"}}>
  <img   src="images/login.png" height="70"  alt="Card image cap" />

</div>
<div class="flex-1 d-flex align-items-start justify-content-center"  style={{width:"100%"}}>

<div class="card-body d-flex flex-column"  style={{width:"100%"}}>
<div style={{width:"100%"}}>
                          <Input
                                       text="Username"
                                       ref="userName"
                                       name="userName"
                                       validator={true}
                                       validate={this.validateUserName}
                                       theme={"transparent"}
                                       value={this.state.userName}
                                       onChange={this.setFieldValue}
                                     mandatory
                                     disabled={this.state.needToCheckOTP}
                                      errorMessage="Username does not contain permissible characters"            
                                       emptyMessage="Application name can't be empty"
                                     />
                                     </div>
                                     <div>
                                     <Input
                                                   text="Password"
                                                   ref="password"
                                                   name="password"
                                                   validator={true}
                                                   validate={this.validatePassword}
                                                      theme={"transparent"}
                                                   type="password"
                                                   value={this.state.password}
                                                   onChange={this.setFieldValue}
                                                 mandatory
                                                 disabled={this.state.needToCheckOTP}
                                                 errorMessage="Password does not contain permissible characters"    
                                                   emptyMessage="Application name can't be empty"
                                                 />
                                                 </div>
                                                 {this.state.needToCheckOTP && !this.state.otpExpired && this.props.otpValid!=="invalid"?                                                 
                                                 <div>
                                                 <div style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                <span style={{textAlign:"center",margin:"auto"}}><button style={{border:"0px", color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}}>An OTP has been sent to your registered email ID.</button></span>
                                                </div>
                                                <div style={{textAlign:"center",border:"0px", color:"#4fbfbd",background:"transparent"}}><Countdown date={Date.now() + 120000} onComplete={()=>this.countdownCompleted()}/></div> {/*10000 - 10 seconds */}
                                                 <div>
                                     <Input
                                                   text="OTP"
                                                   ref="otp"
                                                   name="otp"
                                                   validator={true}
                                                      theme={"transparent"}
                                                   type="password"
                                                   value={this.state.otp}
                                                   onChange={this.setFieldValue}
                                                 mandatory


                                                   emptyMessage="OTP can't be empty"
                                                 />
                                                 </div></div>:null
                                                 }
<div style={{textAlign:"center",width:"100%"}}>
{this.state.needToCheckOTP && !this.state.otpExpired && this.props.otpValid!=="invalid"?                                                 
<ActionButton containerClass={`${this.props.isAuthenticating?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.checkOTP()} style={{width:"100%"}}>Validate OTP</ActionButton>
:null}                                                 
{!this.state.needToCheckOTP?                                                 
<ActionButton style={{width:"100%"}} containerClass={`${this.props.isAuthenticating?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.login()}>Login</ActionButton>
:null}
{!this.state.needToCheckOTP?                                                 
<div style={{paddingTop:"10px"}}>
<span style={{width:"50%",marginTop:"10px"}}><button style={{textDecoration:"underline",color:"#4fbfbd",background:"transparent",border:"0px",cursor:"pointer"}} onClick={()=>this.resetPassword()}>{this.state.resetRequest}Reset Password</button></span>
 </div>
 :null}
</div>
 
 {!this.props.initialState && !this.props.isAuthenticated?<p style={{color:"#B22222"}}>Login Failed!{this.props.ipIncorrect?"You are not authorized to access from this location!": "Please enter a valid user name and password."}</p>:null}
 {this.props.sendingResetEmail?<ComponentLoader/>:null}
 {this.props.sentResetEmail?<p style={{color:"#4fbfbd"}}>An email to reset your password has been sent to your registered email Id!</p>:null}
 {this.props.resetEmailError?<p style={{color:"#B22222"}}>This userName does not exist. Please enter a valid userName!</p>:null}
 {this.props.isAuthenticated && this.props.otpValid=="invalid" && this.state.needToCheckOTP && !this.state.otpExpired?<div><p style={{color:"#B22222"}}>OTP entered is invalid! Please try again.</p><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.loginAgain()}>Login Again</button></div>:null}
 {this.props.isAuthenticated && this.state.otpExpired && this.state.needToCheckOTP?<div><p style={{color:"#B22222"}}>OTP has expired! </p><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.resendOTP()}>Resend OTP</button><button style={{border:"0px", textDecoration:"underline",color:"#4fbfbd",background:"transparent",width:"100%",cursor:"pointer"}} onClick={()=>this.loginAgain()}>Login Again</button></div>:null}
 {this.state.resetRequest?  <DialogBox msg={"Are you sure you want to reset the password for "+this.state.userName+"?"}>
 

  <button class="btn btn-danger" onClick={() => this.confirmResetPassword()}>Yes</button>
    <button class="btn btn-light" onClick={()=>this.setState({resetRequest:false})}>Cancel</button>
  </DialogBox>:null}
</div>
</div>
</div>


    );
  }
}
